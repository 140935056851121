import { createSlice } from '@reduxjs/toolkit';


const initialState = {
	topbarBg: 'dark',
	customizerSidebar: false,
	isRTL: false,
	isDark: false,
	isMiniSidebar: false,
	sidebarBg: 'dark',
	isTopbarFixed: true,
	isMobileSidebar: false,
	isSidebarFixed: true,
	isLayoutHorizontal: false,
	isInnerRightPart: false, // this is for the three column layout right part show hide in table & mobile
	isTabAllegatiVisible: false,
	isTabEmailVisible: false,
	isTabPolizzaVisible: false,
	isTabDiarioVisible: false,
  isTabOpenAIVisible: false,
  isTabShellVisible: false,
	isSpinnerVisible: true,
	stateDisabledTrueFalse: true,
	sessionTicks: 0,    //countdown on session state NOT USED FOR NOW
	lastCallErr: '',
	isInError: false,
  isModalMassimaleGaranzieVisible: false,
	// alertDangerVisible: false,
	// alertWarningVisible: false,
  // alertNoDataEvntoDataRis: false,
	dataState: {
    "filter": null,
		"sort": [
      {
        field: "priorita",
        dir: "asc",
      }/* ,
      {
        field: "dataScadenza",
        dir: "asc",
      } */,
      {
        field: "motivoRisveglio",
        dir: "desc",
      },
    ],
		"skip": 0,
		"take": 10
	},
  filterClear: false,
	schedulerState: {
		"view": "month",
		"date":new Date().toISOString()
	},
	currentActiveTab:"1",
  istruttoriaFilter:false
};

export const CustomizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    ChangeTopbarColor: (state, action) => {
      state.topbarBg = action.payload;
    },
    ToggleCustomizer: (state) => {
      state.customizerSidebar = !state.customizerSidebar;
    },
    ChangeDirection: (state, action) => {
      state.isRTL = action.payload;
    },
    ChangeDarkMode: (state, action) => {
      state.isDark = action.payload;
    },
    ToggleMiniSidebar: (state) => {
      state.isMiniSidebar = !state.isMiniSidebar;
    },
    ChangeSidebarColor: (state, action) => {
      state.sidebarBg = action.payload;
    },
    ToggleTopbar: (state, action) => {
      state.isTopbarFixed = action.payload;
    },
    ToggleMobileSidebar: (state) => {
      state.isMobileSidebar = !state.isMobileSidebar;
    },
    FixedSidebar: (state) => {
      state.isSidebarFixed = !state.isSidebarFixed;
    },
    ToggleHorizontal: (state) => {
      state.isLayoutHorizontal = !state.isLayoutHorizontal;
    },
    ToggleInnerRightPart: (state) => {
      state.isInnerRightPart = !state.isInnerRightPart;
    },
    ToggleTabAllegati: (state) => {
      state.isTabAllegatiVisible = !state.isTabAllegatiVisible;
    },
    ToggleTabEmail: (state) => {
      state.isTabEmailVisible = !state.isTabEmailVisible;
    },
    ToggleTabPolizza: (state) => {
      state.isTabPolizzaVisible = !state.isTabPolizzaVisible;
    },
    ToggleTabDiario: (state) => {
      state.isTabDiarioVisible = !state.isTabDiarioVisible;
    },
    ToggleTabOpenAI: (state) => {
      state.isTabOpenAIVisible = !state.isTabOpenAIVisible;
    },
    ToggleTabShell: (state) => {
      state.isTabShellVisible = !state.isTabShellVisible;
    },
    ToggleTabFinancials: (state) => {
      state.isTabFinancialsVisible = !state.isTabFinancialsVisible;
    },
    ToggleSpinner: (state) => {
      state.isSpinnerVisible = !state.isSpinnerVisible;
    },
    EnabledDisabledStateTrueFalsePresaCarico: (state) => {
      state.stateDisabledTrueFalse = !state.stateDisabledTrueFalse;
    },
    DisabledStateTrueFalsePresaCarico: (state) => {
      state.stateDisabledTrueFalse = (state.stateDisabledTrueFalse) ? state.stateDisabledTrueFalse : !state.stateDisabledTrueFalse ;
    },
    setsessionTicks: (state) => {
      state.sessionTicks = 0;
    },
    setLastCallErr: (state,action) => {
      state.lastCallErr = action.payload;
    },
    setIsInError: (state) => {
      state.isInError = !state.isInError;
    },
	  SetDataState: (state, action) => {
      state.dataState = action.payload;
      console.log("filter",state.dataState);
	  },
    ResetDataState: (state) => {
      const actualState = state.dataState;            
      // eslint-disable-next-line prefer-object-spread
      state.dataState = Object.assign({},  actualState, {filter : null});
	  },  
    SetDataStateSinistri: (state) => {
      const actualState = state.dataState;            
      // eslint-disable-next-line prefer-object-spread
      state.dataState = Object.assign({},  actualState, {filter : {filters:[{logic:"and",filters:[{field:"id",operator:"contains",value:"ist"}]}],logic:"and"}});
	  },  
    ResetDataStateSinistri: (state) => {
      const actualState = state.dataState;            
      // eslint-disable-next-line prefer-object-spread
      state.dataState = Object.assign({},  actualState, {filter : {filters:[{logic:"and",filters:[{field:"id",operator:"contains",value:""}]}],logic:"and"}});
	  },    
	  SetSchedulerState: (state, action) => {
		  state.schedulerState = action.payload;
	  },
	  SetCurrentActiveTab:(state, action)=> {
		  state.currentActiveTab=action.payload
	  },
    SetIstruttoriaFilter:(state, action)=> {
		  state.istruttoriaFilter=action.payload
	  },
    toggleModalMassimaleGaranzie:(state)=> {
      state.isModalMassimaleGaranzieVisible = !state.isModalMassimaleGaranzieVisible;
    },
    toggleModaleSoggTerzoColl:(state)=> {
      state.isModalSoggTerzoCollVisible = !state.isModalSoggTerzoCollVisible;
    },
    toggleModaleSoggTerzoCollModifica:(state)=> {
      state.isModalSoggTerzoCollModificaVisible = !state.isModalSoggTerzoCollModificaVisible;
    }
  },
});

export const {
  ChangeTopbarColor,
  ToggleCustomizer,
  ChangeDirection,
  ChangeDarkMode,
  ToggleMiniSidebar,
  ChangeSidebarColor,
  ToggleTopbar,
  ToggleMobileSidebar,
  FixedSidebar,
  ToggleHorizontal,
  ToggleInnerRightPart,
  ToggleTabAllegati,
  ToggleTabEmail,
  ToggleTabPolizza,
  ToggleTabDiario,
  ToggleTabOpenAI,
  ToggleTabShell,
  ToggleTabFinancials,
  ToggleSpinner,
  EnabledDisabledStateTrueFalsePresaCarico,
  DisabledStateTrueFalsePresaCarico,
  setsessionTicks,
	SetDataState,
  ResetDataState,
  SetDataStateSinistri,
  ResetDataStateSinistri,
	SetSchedulerState,
	SetCurrentActiveTab,
  SetIstruttoriaFilter,
  toggleModalMassimaleGaranzie,
  toggleModaleSoggTerzoColl,
  toggleModaleSoggTerzoCollModifica
} = CustomizerSlice.actions;

export default CustomizerSlice.reducer;
