import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
 
const initialState = {
  listaSinistri: []
};

export const RicercaSinistriSlice = createSlice({
  name: 'ricerca-sinistri',
  initialState,
  reducers: {
    getListaRicercaSinistri: (state, action) => {
      //state.allegati = [];
      state.listaSinistri = action.payload;
    }
  }
});

export const { getListaRicercaSinistri } = RicercaSinistriSlice.actions;

export const fetchRicercaSinistri = (selectedOption, value) => async (dispatch) => {   
  dispatch(getListaRicercaSinistri([]));
  let uriCall;
  if(selectedOption === 'numero polizza'){
    uriCall = `cercaSinistri/numeroPolizza/${value}`;
  } else if(selectedOption === 'Id task') {
    uriCall = `cercaSinistri/idTask/${value}`;
  } else if(selectedOption === 'numero sinistro') {
    uriCall = `cercaSinistri/sinistroId?idPerSinistro=${value}`;
  } else if(selectedOption === 'contraente') {
    uriCall = `cercaSinistri/contraente/${value}`;
  } else if (selectedOption === 'numero sinistro delegataria') {
    uriCall = `cercaSinistri/numeroSinistroExternal/${value}`;
  } else if (selectedOption === 'codice delegataria') {
    uriCall = `cercaSinistri/codiceDelegataria/${value}`;
  } 

  if(value){
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/${uriCall}`;
    const fetchData = async  () => axiosInstance.get(API_URI_CALL);
    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res',res); 
    console.log('err',err);
    if(res?.data?.length > 0){
      return dispatch(getListaRicercaSinistri(res.data))
    }
  }

  return null;
  
 /*  return dispatch(getListaRicercaSinistri(
    [
      { id: "10", numeroPolizza: "20", numeroSinistro: "30", esito: "test" },
      { id: "100", numeroPolizza: "200", numeroSinistro: "300", esito: "test2" }
    ]
  ));    */
}; 

export default RicercaSinistriSlice.reducer;
